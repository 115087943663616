"use strict";

angular.module("informaApp")
    .service("GanttChartMapper", ["d3", "DrugIndicationStatusEnum", "ConstantsSvc", "BiomedtrackerUrlBuilder", 'OmnitureSvc', function (d3, DrugIndicationStatusEnum, ConstantsSvc, BiomedtrackerUrlBuilder, OmnitureSvc) {
        var DAYS_IN_YEAR = 365;

        function statusToString(status, approvedText) {
            switch (status.status) {
                case DrugIndicationStatusEnum.failed:
                    return "Failed in " + ConstantsSvc.Phases[status.failedPhase - 1];
                case DrugIndicationStatusEnum.succeeded:
                    return approvedText;
                case DrugIndicationStatusEnum.inProgress:
                    return "In Progress";
            }
        }

        function statusToColor(status) {
            if (status.status === DrugIndicationStatusEnum.failed) {
                return "#df2020";
            }
            if (status.status === DrugIndicationStatusEnum.succeeded) {
                return "#21AE4B";
            }
            if (status.status === DrugIndicationStatusEnum.inProgress) {
                return "#E8C330";
            }
        }

        function statusToBool(status) {
            return status.status === DrugIndicationStatusEnum.inProgress
                ? null
                : status.status === DrugIndicationStatusEnum.succeeded;
        }

        function durationToString(duration) {
            var format = d3.format(",");

            var years = Math.round(duration / DAYS_IN_YEAR);

            return years.toFixed(2) + " yrs / " + format(duration) + " days";
        }

        function sortByDuration(items) {
            return items.sort(function compare(a, b) {
                return getProgramDurationYear(a.source.total) - getProgramDurationYear(b.source.total);
            });
        }

        function getProgramDurationYear(duration) {
            return duration / DAYS_IN_YEAR;
        }

        function getAllCompaniesForTooltips(item) {
            var companies = _.orderBy(item.companies, ["isLead"], ['desc']);

            companies = companies.map(function (x) {
                return getRow(x.name, "font-weight: 700; font-size: 13px;", x.isLead);
            });

            return companies.join("");
        }

        function getRow(text, style, isLead) {
            var result = "<p style='" + style + "'>" + text;

            if (isLead != null) {
                var text = isLead ? "lead" : "partner";
                var classColor = isLead ? "text-success" : "text-warning";
                var labelStyle = 'margin-left: 5px';

                result += "<sup class='" + classColor + "' style='" + labelStyle + "'>" + text + "</sup>"
            }

            return result + "</p>";
        }

        return {
            sortDataByDuration: sortByDuration,
            mapAll: function (items) {
                var _this = this;

                return items.map(function (x) {
                    return _this.map(x);
                });
            },

            map: function (item) {
                var duration = item.totalDuration;

                return {
                    startingDate: Date.createDateWithoutTimezone(item.startingDate),
                    endDate: Date.createDateWithoutTimezone(item.endDate),
                    status: {
                        title: durationToString(duration),
                        subTitle: statusToString(item.status, "Succeeded"),
                        success: DrugIndicationStatusEnum.toBool(item.status.status)
                    },
                    leftTitle: {
                        text: item.drug.name,
                        link: BiomedtrackerUrlBuilder.buildDrugUrl(item.drug.id),
                        onClick: () => OmnitureSvc.exitToBiomedtracker.trackFromTimelineView()
                    },
                    leftSubTitle: {
                        text: item.indication.name,
                        getTooltip: function () {
                            return getAllCompaniesForTooltips(item) + getRow(item.drugClass.name, "font-size: 13px; margin-bottom: 0;");
                        }
                    },
                    source: item.source
                }
            },

            mapAllForHorizontalbar: function (items) {
                var _this = this;

                return sortByDuration(items.map(function (x) {
                    return _this.mapForHorizontalbar(x);
                }));
            },

            mapForHorizontalbar: function (item) {
                return {
                    mainTitle: item.drug.name,
                    mainTitleLink: BiomedtrackerUrlBuilder.buildDrugUrl(item.drug.id),
                    onMainTitleClick: () => OmnitureSvc.exitToBiomedtracker.trackFromTimelineView(),
                    subTitle: item.indication.name,
                    programResult: statusToString(item.status, "Approved"),
                    statusColor: statusToColor(item.status),
                    status: statusToBool(item.status),
                    daysToYears: getProgramDurationYear,
                    durationToString: durationToString,
                    failedPhase: item.status.failedPhase,
                    source: item.source
                }
            }
        }
    }]);